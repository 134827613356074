import { ColumnDef } from "@tanstack/react-table";
import { FzfResultItem } from "fzf";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";

import ContextMenu from "@components/ContextMenu";
import ErrorWell from "@components/ErrorWell";
import { LabelledSelect } from "@components/FormControls";
import HighlightChars from "@components/HighlightChars";
import Icon from "@components/Icon";
import { IconWithToolTip } from "@components/IconWithToolTip";
import ListItem, { ListItemPlaceholder } from "@components/ListItem";
import {
  BarVerticalColumn,
  BarVerticalMetric,
  MetricsRow,
  NumberMetric,
} from "@components/Metric";
import { Page } from "@components/Page";
import QuantityContainer from "@components/QuantityContainer";
import Scroller from "@components/Scroller";
import SearchBar from "@components/SearchBar";
import SecondaryButton from "@components/SecondaryButton";
import { getStatusLabel } from "@components/ShipmentCard";
import StoreReadinessImage from "@components/StoreReadinessImage";
import { Table } from "@components/Table";
import { useTooltip } from "@components/Tooltip";
import UnitTypeIcon from "@components/UnitTypeIcon";
import {
  LargeDataNotice,
  SmallText,
  UnstyledList,
  Well,
} from "@design/helpers";
import DS from "@design/system";
import RemoveStoreFromGroupModal from "@modals/RemoveStoreFromGroupModal";
import {
  useCompany,
  useDaysGone,
  useEscalations,
  useGroup,
  usePageTitle,
  useSearch,
  useShipments,
  useStoresInGroup,
  useStoresReadiness,
  useUnits,
} from "@state/hooks";
import {
  getEscalationIcon,
  getEscalationTitle,
  openAndNotPausedEscalationsOnly,
} from "@util/escalations";
import { activeUnitsOnly, getStatus } from "@util/firstAidStation";
import { activeShipmentsOnly, getShipmentStatusIcon } from "@util/shipments";
import {
  criticalStockOnly,
  filter_daysGone,
  filter_escalations,
  filter_lowReadyRating,
  filter_offline,
  filter_onlyStoresWithStations,
  getStockReplenishmentStatusIcon,
  useStoresFilter,
} from "@util/store";
import useParamsUpper from "@util/useParamsUpper";
import { useThemeHelper } from "@util/useThemeHelper";

const DaysGone = ({ store }: { store: Api.Store }) => {
  const { palettes } = useTheme();

  const { daysGone } = useDaysGone({ storeId: store.StoreId });
  const { tooltipProps } = useTooltip<HTMLDivElement>(
    daysGone !== null && daysGone !== -1
      ? `${daysGone} days since last ${t("severity.high").toLowerCase()} ${t(
          "term.incident",
        ).toLowerCase()}`
      : `No ${t("severity.high").toLowerCase()} ${t(
          "term.incident_other",
        ).toLowerCase()}`,
  );

  return (
    <div {...tooltipProps} style={{ textAlign: "center" }}>
      <span
        style={{
          color:
            daysGone === null || daysGone === -1
              ? palettes.states.na.background
              : daysGone < 30
                ? palettes.states.bad.background
                : palettes.states.neutral.background,
        }}
      >
        {daysGone !== null && daysGone !== -1 ? `${daysGone} days ago` : "--"}
      </span>
    </div>
  );
};

const StoreStockStatus = ({ store }: { store: Api.Store }) => {
  const { stockReplenishmentStatusPalette } = useThemeHelper();

  const { data: units } = useUnits({ storeId: store?.StoreId });

  const status = useMemo(
    () =>
      units
        ? units
            ?.filter(criticalStockOnly)
            .map((unit) => unit.ReplenishmentStatus)
        : [],
    [units],
  );

  const { tooltipProps } = useTooltip<HTMLDivElement>(
    status?.length > 1
      ? "Multiple critical stock statuses"
      : status?.map((s) => t(`units.replenishmentStatus.${s}.statusPill`)),
  );

  return (
    <div
      {...tooltipProps}
      style={{
        display: "grid",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {status.length > 0 && (
          <Icon
            name={getStockReplenishmentStatusIcon(status[0])}
            color={stockReplenishmentStatusPalette(status[0]).background}
          />
        )}
      </div>
    </div>
  );
};

const ShipmentStatus = ({
  store,

  groupId,
}: {
  store?: Api.Store;

  groupId?: string;
}) => {
  const { shipmentStatusPalette } = useThemeHelper();

  const { data: shipments } = useShipments({
    groupId,
    storeId: store?.StoreId,
    numberDays: 90,
  });

  const status = useMemo(
    () =>
      shipments
        ? shipments
            .filter(activeShipmentsOnly)
            .filter((shipment) => shipment.Store.StoreId === store?.StoreId)
            .map((shipmentStatus) => shipmentStatus.Status)
        : [],
    [shipments, store],
  );

  const { tooltipProps } = useTooltip<HTMLDivElement>(
    status.length > 1
      ? "Multiple active shipments"
      : status.map((s) => getStatusLabel(s)),
  );

  return (
    <div
      {...tooltipProps}
      style={{
        display: "grid",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {status.length > 0 && (
          <Icon
            name={getShipmentStatusIcon(status[0])}
            color={shipmentStatusPalette(status[0]).foreground}
          />
        )}
      </div>
    </div>
  );
};

const UnitStatus = ({ store }: { store: Api.Store }) => {
  const { data: units } = useUnits({ storeId: store.StoreId });

  const activeUnits = useMemo(
    () => (units ? units.filter(activeUnitsOnly) : []),
    [units],
  );

  const { tooltipProps } = useTooltip<HTMLDivElement>(
    activeUnits.length > 4
      ? "Multiple cabinets"
      : activeUnits.map((unit) => getStatus(unit).title).join(", "),
  );

  if (!units) return null;

  return (
    <div
      {...tooltipProps}
      style={{
        display: "grid",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {units
          .filter(activeUnitsOnly)
          .slice(0, 4)
          .map((unit, i) => (
            <div
              key={unit.ControllerSerialNumber}
              style={{
                zIndex: 0,
                marginLeft: i === 0 ? 0 : -12,
                display: "grid",
              }}
            >
              <UnitTypeIcon unitId={unit.ControllerSerialNumber} size={32} />
            </div>
          ))}
      </div>
    </div>
  );
};

const DISPLAY_ESCALATION = 4;

const EscalationStatus = ({ store }: { store?: Api.Store }) => {
  const { escalationsPalette } = useThemeHelper();

  const { data: escalations } = useEscalations({ storeId: store?.StoreId });

  const openEscalations = useMemo(
    () => escalations?.filter(openAndNotPausedEscalationsOnly),
    [escalations],
  );

  const remaingToolTips = openEscalations?.slice(3);

  const { tooltipProps } = useTooltip<HTMLDivElement>(
    <UnstyledList
      style={{
        marginTop: "8px",
        marginBottom: "8px",
      }}
    >
      {remaingToolTips?.map((escalation) => (
        <li
          key={escalation.UnitReminderId}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <Icon
            name={getEscalationIcon(escalation.ReminderType)}
            size={16}
            color={escalationsPalette(escalation).background}
          />
          {getEscalationTitle(escalation.ReminderType)}
        </li>
      ))}
    </UnstyledList>,
  );

  if (!openEscalations?.length) return null;

  return (
    <div>
      <div
        style={{
          display: "grid",
          padding: DS.margins.microCss("rl"),
          gridTemplateColumns: "1fr auto",
          gap: "1px",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        {openEscalations?.length > DISPLAY_ESCALATION ? (
          <>
            <UnstyledList>
              {openEscalations?.slice(0, 3).map((escalation) => (
                <li key={escalation.UnitReminderId}>
                  <IconWithToolTip
                    icon={getEscalationIcon(escalation.ReminderType)}
                    color={escalationsPalette(escalation).background}
                    title={getEscalationTitle(escalation.ReminderType)}
                  />
                </li>
              ))}
            </UnstyledList>
            <QuantityContainer {...tooltipProps}>
              +{openEscalations?.length - DISPLAY_ESCALATION + 1}
            </QuantityContainer>
          </>
        ) : (
          <>
            <UnstyledList horizontal gap={4}>
              {openEscalations
                .slice(0, DISPLAY_ESCALATION)
                .map((escalation) => (
                  <li key={escalation.UnitReminderId}>
                    <IconWithToolTip
                      icon={getEscalationIcon(escalation.ReminderType)}
                      color={escalationsPalette(escalation).background}
                      title={getEscalationTitle(escalation.ReminderType)}
                    />
                  </li>
                ))}
            </UnstyledList>
          </>
        )}
      </div>
    </div>
  );
};

type Sorting =
  | "store-name"
  | "readiness-high"
  | "readiness-low"
  | "days-gone-recent"
  | "days-gone-oldest";

const sort_stores =
  (
    by: Sorting,
    {
      readinesses,
    }: { readinesses?: { storeId: string; readiness?: number }[] },
  ) =>
  (a: FzfResultItem<Api.Store>, b: FzfResultItem<Api.Store>) => {
    switch (by) {
      case "store-name":
        return a.item.Name.localeCompare(b.item.Name);
      case "readiness-high":
        return readinesses
          ? (readinesses.find(
              (readiness) => readiness.storeId === b.item.StoreId,
            )?.readiness ?? Number.NEGATIVE_INFINITY) -
              (readinesses.find(
                (readiness) => readiness.storeId === a.item.StoreId,
              )?.readiness ?? Number.NEGATIVE_INFINITY)
          : 0;
      case "readiness-low":
        return readinesses
          ? (readinesses.find(
              (readiness) => readiness.storeId === a.item.StoreId,
            )?.readiness ?? Number.POSITIVE_INFINITY) -
              (readinesses.find(
                (readiness) => readiness.storeId === b.item.StoreId,
              )?.readiness ?? Number.POSITIVE_INFINITY)
          : 0;
      case "days-gone-recent":
        return (
          new Date(b.item.LastHighSeverityEventDate ?? 0).getTime() -
          new Date(a.item.LastHighSeverityEventDate ?? 0).getTime()
        );
      case "days-gone-oldest":
        return (
          new Date(a.item.LastHighSeverityEventDate ?? Date.now()).getTime() -
          new Date(b.item.LastHighSeverityEventDate ?? Date.now()).getTime()
        );
      default:
        return 0;
    }
  };

const Stores = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { palettes } = useTheme();
  const { escalationsPalette } = useThemeHelper();
  const { groupId } = useParamsUpper<{
    groupId?: string;
  }>();

  const { data: groupEscalations } = useEscalations({ groupId });
  const { data: group } = useGroup(groupId);
  const { data: company } = useCompany();
  const {
    data: storesOriginal,
    isLoading,
    isError,
  } = useStoresInGroup(groupId, true);

  const [sorting, setSorting] = useState<Sorting>("store-name");

  usePageTitle(
    `Stores ${group ? " - " + group.Name : company ? " - " + company.Name : ""}`,
  );

  const [
    {
      lowReadyRating,
      daysGone: filterDaysGone,
      offline,
      onlyStoresWithStations,
      escalationTypes,
    },
    setStoresFilter,
  ] = useStoresFilter();

  const hasFilter = useMemo(
    () =>
      lowReadyRating ||
      filterDaysGone ||
      offline ||
      onlyStoresWithStations ||
      escalationTypes.length > 0,
    [
      escalationTypes,
      filterDaysGone,
      lowReadyRating,
      offline,
      onlyStoresWithStations,
    ],
  );

  const {
    search,
    q,
    setList: setSearchableStores,
    results: storesSearched,
  } = useSearch<Api.Store>([], (store) => store.Name);

  const storesIds = useMemo(
    () => storesSearched?.map((result) => result.item.StoreId) ?? [],
    [storesSearched],
  );

  const { readinesses, isLoading: isReadinessLoading } =
    useStoresReadiness(storesIds);
  const { daysGone, isLoading: isDaysGoneLoading } = useDaysGone({ storesIds });
  const { data: units, isLoading: isUnitsLoading } = useUnits({ storesIds });

  // Stats
  const readyRating = useMemo(() => {
    const readyRating = readinesses
      ? Number(
          readinesses
            .filter(({ readiness }) => (readiness ?? -1) > -1)
            .reduce(
              (p, { readiness }, _i, { length }) =>
                p + (readiness ?? 0) / length,
              0,
            )
            .toFixed(1),
        )
      : 0;

    return readyRating === Infinity ? (
      "--"
    ) : (
      <div style={{ position: "relative" }}>
        {readyRating}
        <sup style={{ fontSize: "0.5em" }}>%</sup>
      </div>
    );
  }, [readinesses]);

  const stationsOnline = useMemo(
    () =>
      `${
        units?.filter(activeUnitsOnly).filter((unit) => unit.IsActive).length ??
        0
      }/${units?.filter(activeUnitsOnly).length ?? 0}`,
    [units],
  );

  const { data: allEscalations } = useEscalations({ storesIds });

  const escalations = useMemo(() => {
    const openEscalations =
      allEscalations?.filter(openAndNotPausedEscalationsOnly) ?? [];
    const values = {
      aed: openEscalations.filter(
        (escalation) =>
          escalation.ReminderType === "AedInspection" ||
          escalation.ReminderType === "AedPartsReplacement" ||
          escalation.ReminderType === "AedPartsReplacementConfirmation",
      ),
      powerOff: openEscalations.filter(
        (escalation) => escalation.ReminderType === "PowerOff",
      ),
      missingTool: openEscalations.filter(
        (escalation) => escalation.ReminderType === "MissingTool",
      ),
      replenishment: openEscalations.filter(
        (escalation) => escalation.ReminderType === "Replenishment",
      ),
      expiredStock: openEscalations.filter(
        (escalation) => escalation.ReminderType === "ExpiredStock",
      ),
    };

    return {
      ...values,
      count: openEscalations.length,
      max: Math.max(
        ...Object.keys(values).map(
          (v) => values[v as keyof typeof values].length,
        ),
      ),
    };
  }, [allEscalations]);

  const escalationData = [
    escalations.aed,
    escalations.powerOff,
    escalations.missingTool,
    escalations.replenishment,
    escalations.expiredStock,
  ]
    .filter((group) => group.length)
    .map((escalationGroup) => ({
      id: escalationGroup[0].ReminderType,
      title: `${getEscalationTitle(escalationGroup[0].ReminderType)}: ${
        escalationGroup.length
      }`,
      icon: getEscalationIcon(escalationGroup[0].ReminderType),
      count: escalationGroup.length,
      color: escalationsPalette(escalationGroup).background,
    }));

  const storesList = useMemo(
    () =>
      (storesSearched ?? [])

        .filter(filter_onlyStoresWithStations(onlyStoresWithStations, units))
        .filter(filter_lowReadyRating(lowReadyRating, readinesses))
        .filter(filter_daysGone(filterDaysGone))
        .filter(filter_offline(offline, units))
        .filter(
          filter_escalations(
            !!escalationTypes.length,
            escalationTypes,
            groupEscalations?.filter(openAndNotPausedEscalationsOnly),
          ),
        )

        .sort(sort_stores(sorting, { readinesses })),
    [
      escalationTypes,
      filterDaysGone,
      groupEscalations,
      lowReadyRating,
      offline,
      onlyStoresWithStations,
      readinesses,
      sorting,
      storesSearched,
      units,
    ],
  );

  const data = useMemo(() => storesList ?? [], [storesList]);

  const [storeToRemove, setStoreToRemove] = useState<Api.Store | null>();

  const [showRemoveStoreFromGroupModal, closeRemoveStoreFromGroupModal] =
    useModal(() => {
      if (!group || !storeToRemove) return null;

      return (
        <RemoveStoreFromGroupModal
          store={storeToRemove}
          group={group}
          onConfirm={closeRemoveStoreFromGroupModal}
          onClose={closeRemoveStoreFromGroupModal}
        />
      );
    }, [group, storeToRemove]);

  const handleSearchChange = useCallback((q: string) => search(q), [search]);

  const handleRemoveStoreFromGroup = useCallback(
    (store: Api.Store) => {
      if (!group) return null;

      setStoreToRemove(store);
      showRemoveStoreFromGroupModal();
    },
    [group, showRemoveStoreFromGroupModal],
  );

  const handleStoreClick = useCallback(
    (store?: Api.Store) => {
      if (!store) return;
      history.push(`/store/${store.StoreId}/overview`);
    },
    [history],
  );

  const handleClearFilterClick = useCallback(() => {
    setStoresFilter({
      lowReadyRating: false,
      daysGone: false,
      offline: false,
      onlyStoresWithStations: false,

      escalationTypes: [],
    });
    search("");
  }, [search, setStoresFilter]);

  const columns = useMemo<ColumnDef<FzfResultItem<Api.Store>>[]>(
    () => [
      {
        id: "list-item",
        header: "Name",
        meta: { width: "1fr" },
        cell: ({ row: { original: store } }) =>
          store && (
            <ListItem
              image={<StoreReadinessImage storeId={store.item.StoreId ?? ""} />}
              compact
              title={
                <HighlightChars
                  str={store.item.Name}
                  indices={store.positions}
                />
              }
              description={store.item.LocationAddress.AsOneLine}
            />
          ),
      },

      {
        id: "days-gone",
        header: "Days since",
        cell: ({ row: { original: store } }) =>
          store && <DaysGone store={store.item} />,
      },

      {
        id: "upcoming-shipments",

        cell: ({ row: { original: store } }) =>
          store && <ShipmentStatus store={store.item} groupId={groupId} />,
      },

      {
        id: "stock-status",

        cell: ({ row: { original: store } }) =>
          store && <StoreStockStatus store={store.item} />,
      },

      {
        id: "stations",
        cell: ({ row: { original: store } }) =>
          store && <UnitStatus store={store.item} />,
      },
      {
        id: "escalations",
        cell: ({ row: { original: store } }) =>
          store && <EscalationStatus store={store.item} />,
      },
      {
        id: "context",
        cell: ({ row: { original: store } }) => (
          <ContextMenu
            label={`${t("term.store_one")} options`}
            placement="right-center"
            items={[
              {
                key: "remove-store",
                icon: "minus",
                label: "Remove from group",
                onClick: (close) => {
                  if (store && store.item)
                    handleRemoveStoreFromGroup(store.item);
                  close();
                },
              },
            ]}
          />
        ),
      },
    ],
    [groupId, handleRemoveStoreFromGroup, t],
  );

  useEffect(
    () => setSearchableStores(storesOriginal),
    [setSearchableStores, storesOriginal],
  );

  return (
    <Page>
      <div
        style={{
          display: "grid",
          overflow: "hidden",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <div
          style={{
            padding: DS.margins.regularCss("trl"),
            marginBottom: DS.margins.micro,
          }}
        >
          <div
            style={{
              padding: DS.margins.micro,
              background: palettes.well.background,
              borderRadius: DS.radii.largeItem,

              display: "grid",
              gridAutoFlow: "column",
              gap: DS.margins.regular,
              justifyContent: "flex-start",
              gridTemplateColumns: "1fr 200px 200px",
            }}
          >
            <SearchBar
              placeholder={`Search for ${t("term.store_other").toLowerCase()}…`}
              value={q}
              onSearch={handleSearchChange}
            />
            <LabelledSelect
              compact
              label={`${t("term.store_other")} to list`}
              options={[
                {
                  value: "show",
                  label: `Show all ${t("term.store_other").toLowerCase()}`,
                },
                {
                  value: "hide",
                  label: `Hide ${t(
                    "term.store_other",
                  ).toLowerCase()} with no cabinets`,
                },
              ]}
              value={onlyStoresWithStations ? "hide" : "show"}
              onChange={(e) =>
                setStoresFilter({
                  onlyStoresWithStations: e.target.value === "hide",
                })
              }
            />
            <LabelledSelect
              compact
              label="Sort by"
              options={
                [
                  {
                    value: "store-name",
                    label: `${t("term.store_one")} name`,
                  },
                  {
                    value: "readiness-high",
                    label: "Ready rating (highest first)",
                  },
                  {
                    value: "readiness-low",
                    label: "Ready rating (lowest first)",
                  },
                  {
                    value: "days-gone-recent",
                    label: `Days since last ${t(
                      "severity.high",
                    ).toLowerCase()} ${t(
                      "term.incident_one",
                    ).toLowerCase()} (recent first)`,
                  },
                  {
                    value: "days-gone-oldest",
                    label: `Days since last ${t(
                      "severity.high",
                    ).toLowerCase()} ${t(
                      "term.incident_one",
                    ).toLowerCase()} (oldest first)`,
                  },
                ] as { value: Sorting; label: string }[]
              }
              value={sorting}
              onChange={(e) => setSorting(e.target.value as Sorting)}
            />
          </div>
          <MetricsRow style={{ marginTop: DS.margins.micro }}>
            <BarVerticalMetric
              label="Actions to do"
              value={escalations.count}
              size="heavy"
              selected={escalationTypes}
              onChange={(escalation) =>
                setStoresFilter((filter) =>
                  filter.escalationTypes.includes(escalation)
                    ? {
                        escalationTypes: filter.escalationTypes.filter(
                          (a) => a !== escalation,
                        ),
                      }
                    : {
                        escalationTypes: [
                          ...filter.escalationTypes,
                          escalation,
                        ],
                      },
                )
              }
              data={
                [...escalationData] as BarVerticalColumn<Api.UnitReminderType>[]
              }
            />
            <NumberMetric
              label="Ready rating"
              title={`Show ${t(
                "term.store_other",
              ).toLowerCase()} with low ready rating`}
              value={readyRating}
              isLoading={isReadinessLoading}
              selected={lowReadyRating}
              onChange={() =>
                setStoresFilter((filter) => ({
                  lowReadyRating: !filter.lowReadyRating,
                }))
              }
            />
            <NumberMetric
              label={`Days since last ${t("severity.high").toLowerCase()} ${t(
                "term.incident_one",
              ).toLowerCase()}`}
              title={`Show ${t(
                "term.store_other",
              ).toLowerCase()} with recent ${t(
                "severity.high",
              ).toLowerCase()} ${t("term.incident_other").toLowerCase()}`}
              value={daysGone}
              isLoading={isDaysGoneLoading}
              selected={filterDaysGone}
              onChange={() =>
                setStoresFilter((filter) => ({ daysGone: !filter.daysGone }))
              }
            />
            <NumberMetric
              label="Cabinets online"
              title={`Show ${t(
                "term.store_other",
              ).toLowerCase()} with offline cabinets`}
              value={stationsOnline}
              isLoading={isUnitsLoading}
              selected={offline}
              onChange={() =>
                setStoresFilter((filter) => ({ offline: !filter.offline }))
              }
            />
          </MetricsRow>

          {hasFilter && (
            <Well
              style={{
                marginTop: DS.margins.micro,
                textAlign: "center",
                background: palettes.messages.notice.background,
              }}
            >
              <SmallText style={{ color: palettes.messages.notice.foreground }}>
                Showing <strong>{storesList?.length ?? 0}</strong> of{" "}
                <strong>
                  {t("term.store", {
                    count: storesOriginal?.length ?? 0,
                  }).toLowerCase()}
                </strong>
                .{" "}
                <SecondaryButton inline onClick={handleClearFilterClick}>
                  Clear the filters
                </SecondaryButton>{" "}
                to show all {t("term.store_other").toLowerCase()}.
              </SmallText>
            </Well>
          )}
        </div>

        <Scroller>
          <>
            {q && (!storesList || !storesList.length) && (
              <LargeDataNotice>
                No {t("term.store_other").toLowerCase()} matching{" "}
                <strong>{q}</strong> found.
              </LargeDataNotice>
            )}

            {isLoading ? (
              <div style={{ padding: DS.margins.regularCss("rbl") }}>
                {Array.from(new Array(6)).map((_v, i) => (
                  <div
                    key={`placeholder-${i}`}
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      borderBottom: `solid 1px ${palettes.body.border}`,
                    }}
                  >
                    <ListItemPlaceholder />
                  </div>
                ))}
              </div>
            ) : isError ? (
              <div style={{ margin: DS.margins.regularCss("rl") }}>
                <ErrorWell style={{ textAlign: "center" }}>
                  <Icon
                    name="exclamation-alt-circle"
                    color={palettes.states.bad.background}
                  />{" "}
                  There was a problem loading{" "}
                  {t("term.store_other").toLowerCase()}.
                </ErrorWell>
              </div>
            ) : (
              <div style={{ padding: DS.margins.regularCss("rbl") }}>
                <Table
                  data={data}
                  columns={columns}
                  onRowClick={(row) => handleStoreClick(row.original?.item)}
                />
              </div>
            )}
          </>
        </Scroller>
      </div>
    </Page>
  );
};

export default Stores;
