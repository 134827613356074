import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import {
  MetricsRow,
  DonutMetric,
  BarVerticalColumn,
  BarVerticalMetric,
} from "@components/Metric";
import MultiSelect from "@components/MultiSelect";
import { Page } from "@components/Page";
import Scroller from "@components/Scroller";
import SearchBar from "@components/SearchBar";
import SecondaryButton from "@components/SecondaryButton";
import UnitCard, { UnitCardPlaceholder } from "@components/UnitCard";
import { LargeDataNotice, SmallText, Well } from "@design/helpers";
import DS from "@design/system";
import { cast } from "@lang/lang";
import EditUnitModal from "@modals/EditUnitModal";
import RequestAddressChangeModal from "@modals/RequestAddressChangeModal";
import ScheduleInspectionModal from "@modals/ScheduleInspectionModal";
import {
  getEscalationIcon,
  getEscalationTitle,
  openAndNotPausedEscalationsOnly,
} from "@util/escalations";
import {
  Active,
  Ready,
  UnitStockStatus,
  filter_active,
  filter_daysGone,
  filter_escalations,
  filter_readiness,
  filter_stockLevels,
  getUnitTypes,
  useUnitsFilter,
} from "@util/unit";
import useParamsUpper from "@util/useParamsUpper";
import { useTerminologies } from "@util/useTerminologies";
import { useThemeHelper } from "@util/useThemeHelper";

import {
  useStore,
  useGroup,
  useSearch,
  usePageTitle,
  useCompany,
  useUnits,
  useStoresInGroup,
  useEscalations,
  useDashboard,
} from "../state/hooks";

type Filterable = {
  id: string;
  name: string;
};

const PeopleGrid = styled.ul`
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  gap: ${DS.margins.regular};
`;

const Units = () => {
  const history = useHistory();
  const location = useLocation();
  const { palettes } = useTheme();
  const { escalationsPalette } = useThemeHelper();
  const allStoresMatch = useRouteMatch(["/all-stores"]);
  const { storeId, groupId } = useParamsUpper<{
    storeId?: string;
    groupId?: string;
  }>();

  const initialized = useRef(false);

  const { data: store } = useStore(storeId);
  const { data: company } = useCompany();
  const { t } = useTranslation();

  const theGroupId = groupId ?? store?.SingleStoreGroupID;
  const { getUnitType, devicesReadyCaption, devicesOnlineCaption } =
    useTerminologies();

  const group = useGroup(theGroupId);
  const {
    data: units,
    isLoading,
    fetchStatus,
  } = useUnits({
    storeId,
    groupId,
    allStores: !!allStoresMatch,
  });
  const stores = useStoresInGroup(theGroupId, true);

  const [selectedUnit, setSelectedUnit] = useState<Api.Unit | null>();
  const [filterableStores, setFilterableStores] = useState<Filterable[]>([]);

  const dashboard = useDashboard({
    allStores: true,
    groupId,
    storeId,
    numberDays: 30,
    includeLowIncidents: false,
  });

  useEffect(() => {
    if (!initialized.current) {
      history.replace({
        pathname: location.pathname,
      });
    }

    initialized.current = true;
  }, [history, location]);

  usePageTitle(
    `Units ${
      store
        ? " - " + store.Name
        : group.data
          ? " - " + group.data.Name
          : company
            ? " - " + company.Name
            : ""
    }`,
  );

  const [
    {
      escalationTypes,
      stockLevels,
      active,
      readiness,
      daysGone,
      unitTypeFilter,
      storesFilter,
    },
    setUnitFilter,
  ] = useUnitsFilter();

  const hasFilter = useMemo<boolean>(
    () =>
      escalationTypes.length > 0 ||
      !!daysGone ||
      !!readiness ||
      !!active ||
      stockLevels.length > 0 ||
      unitTypeFilter.length > 0 ||
      storesFilter.length > 0,
    [
      active,
      daysGone,
      escalationTypes,
      readiness,
      stockLevels,
      storesFilter.length,
      unitTypeFilter.length,
    ],
  );

  const filterUnitTypeFn = useCallback(
    (unit: Api.Unit) =>
      unitTypeFilter.length === 0 ||
      unitTypeFilter.some((unitType) => unitType.id === unit.UnitType),
    [unitTypeFilter],
  );

  const filterStoresFn = useMemo(() => {
    if (!stores.data || storesFilter.length === 0) return () => true;

    return (unit: Api.Unit) =>
      storesFilter && storesFilter.some((s) => s.id === unit.StoreId);
  }, [stores.data, storesFilter]);

  const storesIds = useMemo(
    () => units?.map((unit) => unit.StoreId) ?? [],
    [units],
  );

  const { data: groupEscalations } = useEscalations({ groupId });

  const { data: allEscalations } = useEscalations({ storesIds });

  const escalations = useMemo(() => {
    const openEscalations =
      allEscalations?.filter(openAndNotPausedEscalationsOnly) ?? [];
    const values = {
      aed: openEscalations.filter(
        (escalation) =>
          escalation.ReminderType === "AedInspection" ||
          escalation.ReminderType === "AedPartsReplacement" ||
          escalation.ReminderType === "AedPartsReplacementConfirmation",
      ),
      powerOff: openEscalations.filter(
        (escalation) => escalation.ReminderType === "PowerOff",
      ),
      missingTool: openEscalations.filter(
        (escalation) => escalation.ReminderType === "MissingTool",
      ),
      replenishment: openEscalations.filter(
        (escalation) => escalation.ReminderType === "Replenishment",
      ),
      expiredStock: openEscalations.filter(
        (escalation) => escalation.ReminderType === "ExpiredStock",
      ),
    };

    return {
      ...values,
      count: openEscalations.length,
      max: Math.max(
        ...Object.keys(values).map(
          (v) => values[v as keyof typeof values].length,
        ),
      ),
    };
  }, [allEscalations]);

  const escalationData = [
    escalations.aed,
    escalations.powerOff,
    escalations.missingTool,
    escalations.replenishment,
    escalations.expiredStock,
  ]
    .filter((group) => group.length)
    .map((escalationGroup) => ({
      id: escalationGroup[0].ReminderType,
      title: `${getEscalationTitle(escalationGroup[0].ReminderType)}: ${
        escalationGroup.length
      }`,
      icon: getEscalationIcon(escalationGroup[0].ReminderType),
      count: escalationGroup.length,
      color: escalationsPalette(escalationGroup).background,
    }));

  const {
    search,
    q,
    list: searchableUnits,
    setList: setSearchableUnits,
    results: unitsFiltered,
  } = useSearch<Api.Unit>(
    [],
    (unit) =>
      `${unit.UnitName} ${unit.ComplianceLabel} ${unit.CustomerDeviceId}`,
  );

  const unitsList = useMemo(
    () =>
      (unitsFiltered ?? [])
        .filter(
          filter_escalations(
            !!escalationTypes.length,
            escalationTypes,
            groupEscalations?.filter(openAndNotPausedEscalationsOnly),
          ),
        )

        .filter(filter_stockLevels(stockLevels))
        .filter(filter_active(active))
        .filter(filter_daysGone(!!daysGone, stores.data))

        .filter(filter_readiness(readiness)),

    [
      active,
      daysGone,
      escalationTypes,
      groupEscalations,
      readiness,
      stockLevels,
      stores.data,
      unitsFiltered,
    ],
  );

  const unitTypes = useMemo(
    () =>
      getUnitTypes(units ?? []).map((type) => {
        return {
          id: type,
          name: getUnitType(type, true),
        };
      }),
    [getUnitType, units],
  );

  const [showEditUnitModal, closeEditUnitModal] = useModal(
    () => (
      <EditUnitModal
        unitId={selectedUnit?.ControllerSerialNumber ?? ""}
        onClose={() => {
          setSelectedUnit(null);
          closeEditUnitModal();
        }}
      />
    ),
    [selectedUnit?.ControllerSerialNumber],
  );

  const [showScheduleInspectionModal, closeScheduleInspectionModal] = useModal(
    () => (
      <ScheduleInspectionModal
        controllerSerialNumber={selectedUnit?.ControllerSerialNumber ?? ""}
        onClose={() => {
          setSelectedUnit(null);
          closeScheduleInspectionModal();
        }}
      />
    ),
    [selectedUnit],
  );

  const [showRequestAddressChangeModal, closeRequestAddressChangeModal] =
    useModal(
      () => (
        <RequestAddressChangeModal
          controllerSerialNumber={selectedUnit?.ControllerSerialNumber ?? ""}
          onClose={() => {
            setSelectedUnit(null);
            closeRequestAddressChangeModal();
          }}
        />
      ),
      [selectedUnit],
    );

  const handleSearchChange = useCallback((q: string) => search(q), [search]);

  const handleClearFilterClick = useCallback(() => {
    setUnitFilter({
      daysGone: null,
      readiness: null,
      active: null,
      escalationTypes: [],
      stockLevels: [],
      unitTypeFilter: [],
      storesFilter: [],
    });
    search("");

    history.replace("./devices");
  }, [history, search, setUnitFilter]);

  const handleUnitClick = useCallback(
    (unit: Api.Unit) => {
      setSelectedUnit(unit);
      showEditUnitModal();
    },
    [showEditUnitModal],
  );

  const handleScheduleInspectionClick = useCallback(
    (unit: Api.Unit) => {
      setSelectedUnit(unit);
      showScheduleInspectionModal();
    },
    [showScheduleInspectionModal],
  );

  const handleRequestAddressChangeClick = useCallback(
    (unit: Api.Unit) => {
      setSelectedUnit(unit);
      showRequestAddressChangeModal();
    },
    [showRequestAddressChangeModal],
  );

  useEffect(() => {
    setSearchableUnits(units?.filter(filterUnitTypeFn).filter(filterStoresFn));
  }, [units, filterStoresFn, filterUnitTypeFn, setSearchableUnits]);

  useEffect(() => {
    if (!stores.data) {
      setFilterableStores([]);
      return;
    }

    setFilterableStores(
      stores.data.map((store) => ({
        id: store.StoreId,
        name: store.Name,
      })),
    );
  }, [stores.data]);

  const handleFilteredStockLevel = useCallback(
    (stockLevel: UnitStockStatus) =>
      setUnitFilter((filter) =>
        filter.stockLevels.includes(stockLevel)
          ? {
              stockLevels: filter.stockLevels.filter((s) => s !== stockLevel),
            }
          : {
              stockLevels: [...filter.stockLevels, stockLevel],
            },
      ),
    [setUnitFilter],
  );

  const handleFilterOnlineUnits = useCallback(
    (active: Active) =>
      setUnitFilter((filter) => ({
        ...filter,
        active: filter.active !== active ? active : null,
      })),
    [setUnitFilter],
  );

  const handleFilterUnitReadiness = useCallback(
    (readiness: Ready) =>
      setUnitFilter((filter) => ({
        ...filter,
        readiness: filter.readiness !== readiness ? readiness : null,
      })),
    [setUnitFilter],
  );

  return (
    <Page>
      <div
        style={{
          display: "grid",
          overflow: "hidden",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <div
          style={{
            padding: DS.margins.regularCss("trl"),
            marginBottom: DS.margins.micro,
          }}
        >
          <div
            style={{
              padding: DS.margins.micro,
              background: palettes.well.background,
              borderRadius: DS.radii.largeItem,

              display: "grid",
              gridAutoFlow: "column",
              gap: DS.margins.regular,
              justifyContent: "flex-start",
              gridTemplateColumns: "1fr 200px 200px",
            }}
          >
            <SearchBar
              placeholder={t("units.searchPlaceholder")}
              value={q}
              onSearch={handleSearchChange}
            />

            {unitTypes.length > 1 && (
              <MultiSelect
                label={t("units.filterByType")}
                items={unitTypes as Filterable[]}
                value={unitTypeFilter}
                searchPlaceHolder={`Type ${t(
                  "term.store_one",
                ).toLowerCase()} name to narrow down options`}
                getKey={(unit) => unit.id}
                getLabel={(unit) => unit.name}
                onItemAdded={(unit) => {
                  setUnitFilter((prev) => ({
                    ...prev,
                    unitTypeFilter: [...prev.unitTypeFilter, unit],
                  }));
                }}
                onItemRemoved={(unit) => {
                  setUnitFilter((prev) => ({
                    ...prev,
                    unitTypeFilter: [
                      ...prev.unitTypeFilter.filter((g) => g.id !== unit.id),
                    ],
                  }));
                }}
                itemRenderer={(store) => store.name}
              />
            )}

            {groupId && (
              <MultiSelect
                label={t("units.filterByStore")}
                items={filterableStores}
                value={storesFilter}
                isSearchEnabled={true}
                searchPlaceHolder={`Type ${t(
                  "term.store_one",
                ).toLowerCase()} name to narrow down options`}
                getKey={(store) => store.id}
                getLabel={(store) => store.name}
                onItemAdded={(store) => {
                  setUnitFilter((prev) => ({
                    ...prev,
                    storesFilter: [...prev.storesFilter, store],
                  }));
                }}
                onItemRemoved={(store) => {
                  setUnitFilter((prev) => ({
                    ...prev,
                    storesFilter: [
                      ...prev.storesFilter.filter((g) => g.id !== store.id),
                    ],
                  }));
                }}
                itemRenderer={(store) => store.name}
              />
            )}
          </div>
          <MetricsRow style={{ marginTop: DS.margins.micro }}>
            <BarVerticalMetric
              label={t("metrics.actionsToDo.title")}
              value={escalations.count}
              size="heavy"
              selected={escalationTypes}
              onChange={(escalation) =>
                setUnitFilter((filter) =>
                  filter.escalationTypes.includes(escalation)
                    ? {
                        escalationTypes: filter.escalationTypes.filter(
                          (a) => a !== escalation,
                        ),
                      }
                    : {
                        escalationTypes: [
                          ...filter.escalationTypes,
                          escalation,
                        ],
                      },
                )
              }
              data={
                [...escalationData] as BarVerticalColumn<Api.UnitReminderType>[]
              }
            />
            <DonutMetric
              label={t("metrics.unitStockStatus.title")}
              selected={stockLevels}
              onChange={(stockLevel) => handleFilteredStockLevel(stockLevel)}
              data={[
                {
                  id: "stocked",
                  title: t("metrics.unitStockStatus.caption.stocked"),
                  count: dashboard.data?.Dashboard.UnitsStocked ?? 0,
                  color: palettes.states.good.background,
                },
                {
                  id: "in-transit",
                  title: t("metrics.unitStockStatus.caption.inTransit"),
                  count: dashboard.data?.Dashboard.UnitsRefillInTransit ?? 0,
                  color: palettes.states.neutral.background,
                },
                {
                  id: "needs-refill",
                  title: t("metrics.unitStockStatus.caption.needsRefill"),
                  count:
                    dashboard.data?.Dashboard.UnitsNeedRefillNonCritical ?? 0,
                  color: palettes.states.warning.background,
                },
                {
                  id: "critical",
                  title: t("metrics.unitStockStatus.caption.critical"),
                  count: dashboard.data?.Dashboard.UnitsCritical ?? 0,
                  color: palettes.states.bad.background,
                },
              ]}
              isLoading={dashboard.isLoading}
              isError={dashboard.isError}
              showLegend
            />
            <DonutMetric
              label={t("metrics.unitsOnline.title")}
              selected={active}
              onChange={(active) => handleFilterOnlineUnits(active)}
              caption={devicesOnlineCaption(
                dashboard.data?.Dashboard.UnitsActive ?? 0,
                dashboard.data?.Dashboard.UnitsTotal ?? 0,
                dashboard.data?.Dashboard.UnitsTurnedOff ?? 0,
              )}
              icon="plug"
              data={[
                {
                  id: "online",
                  title: "Online",
                  count: dashboard.data?.Dashboard.UnitsActive ?? 0,
                  color: palettes.states.good.background,
                },
                {
                  id: "offline",
                  title: "Offline",
                  count: dashboard.data?.Dashboard.UnitsTurnedOff ?? 0,
                  color: palettes.states.bad.background,
                },
              ]}
              isLoading={dashboard.isLoading}
              isError={dashboard.isError}
              half
            />
            <DonutMetric
              label={t("metrics.unitsReady.title")}
              selected={readiness}
              onChange={(readiness) => handleFilterUnitReadiness(readiness)}
              caption={devicesReadyCaption(
                dashboard.data?.Dashboard.UnitsReady ?? 0,
                dashboard.data?.Dashboard.UnitsTotal ?? 0,
              )}
              icon="shield-alt"
              data={[
                {
                  id: "ready",
                  title: t("metrics.unitsReady.value.ready"),
                  count: dashboard.data?.Dashboard.UnitsReady ?? 0,
                  color: palettes.states.good.background,
                },
                {
                  id: "not-ready",
                  title: t("metrics.unitsReady.value.notReady"),
                  count:
                    (dashboard.data?.Dashboard.UnitsTotal ?? 0) -
                    (dashboard.data?.Dashboard.UnitsReady ?? 0),
                  color: palettes.states.bad.background,
                },
              ]}
              isLoading={dashboard.isLoading}
              isError={dashboard.isError}
              half
            />
          </MetricsRow>

          {!q && !!searchableUnits?.length && unitsList.length === 0 ? (
            <Well
              style={{
                marginTop: DS.margins.micro,
                textAlign: "center",
                background: palettes.messages.notice.background,
              }}
            >
              <SmallText style={{ color: palettes.messages.notice.foreground }}>
                There are no {t("term.unit_other")} that match current filter.{" "}
                <SecondaryButton inline onClick={handleClearFilterClick}>
                  Clear the filters
                </SecondaryButton>{" "}
                to show all {t("term.unit_other")}.
              </SmallText>
            </Well>
          ) : (
            !!searchableUnits?.length &&
            hasFilter && (
              <Well
                style={{
                  marginTop: DS.margins.micro,
                  textAlign: "center",
                  background: palettes.messages.notice.background,
                }}
              >
                <SmallText
                  style={{ color: palettes.messages.notice.foreground }}
                >
                  <Trans
                    i18nKey="units.filter"
                    count={searchableUnits.length ?? 0}
                  >
                    Showing{" "}
                    <strong>
                      {cast({ visible: unitsList.length ?? 0 })} of{" "}
                      {cast({ count: searchableUnits.length ?? 0 })}
                    </strong>{" "}
                    units.{" "}
                    <SecondaryButton inline onClick={handleClearFilterClick}>
                      Clear the filters
                    </SecondaryButton>{" "}
                    to show all units.
                  </Trans>
                </SmallText>
              </Well>
            )
          )}

          {!isLoading && searchableUnits && searchableUnits.length === 0 && (
            <LargeDataNotice>
              {storeId ? t("units.noUnitsInStore") : t("units.noUnitsInGroup")}
            </LargeDataNotice>
          )}

          {q && (!unitsFiltered || !unitsFiltered.length) && (
            <LargeDataNotice>
              <Trans i18nKey="units.noMatches" count={4}>
                No units matching <strong>{cast({ q })}</strong> found.
              </Trans>
            </LargeDataNotice>
          )}
        </div>

        <Scroller>
          <div style={{ padding: DS.margins.regularCss("rbl") }}>
            <PeopleGrid aria-label="People list">
              {fetchStatus === "fetching" ? (
                <>
                  <UnitCardPlaceholder />
                  <UnitCardPlaceholder />
                  <UnitCardPlaceholder />
                  <UnitCardPlaceholder />
                </>
              ) : (
                unitsList &&
                unitsList
                  .sort((a, b) =>
                    q
                      ? 0
                      : a.item.UnitName.toUpperCase() <
                          b.item.UnitName.toUpperCase()
                        ? -1
                        : 1,
                  )
                  .map(({ item: unit, positions }) => (
                    <UnitCard
                      key={unit.ControllerSerialNumber}
                      showStoreName={!!groupId}
                      highlight={positions}
                      unit={unit}
                      onClick={() => handleUnitClick(unit)}
                      onScheduleInspection={() =>
                        handleScheduleInspectionClick(unit)
                      }
                      onRequestAddressChange={() =>
                        handleRequestAddressChangeClick(unit)
                      }
                    />
                  ))
              )}
            </PeopleGrid>
          </div>
        </Scroller>
      </div>
    </Page>
  );
};

export default Units;
